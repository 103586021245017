<template>
    <div id="category-page">
        <section class="ant-layout">

            <header class="ant-layout-header">
                <Head></Head>
            </header>

            <main class="ant-layout-content main-section" >
                <div v-if="data">
                    <a-row :gutter="[8,8]" v-for="row in totalRow" :key="row">
                        <a-col :span="4" v-for="column in calcColumnByRow(row)" :key="column">
                            <div>
                                <!-- :error-img="../assets/images/placeholder.png" -->
                                <div style="position:relative">
                                    <!-- 年份 -->
                                    <span style="position:absolute; color:#4e2d03; font-size: 14px; font-weight: 800; z-index:100; left:0px; background-image: linear-gradient(90deg,#ffc19f,#fff7d9)">
                                        {{ getMoiveByRowAndColumn(row, column).year }}
                                    </span>

                                    <!-- 评分 -->
                                    <span style="position:absolute; color:#ff6500; font-size: 30px; font-weight: 800; z-index:100; bottom:0px; right:0px; background-image: linear-gradient(90deg,#ffc19f,#fff7d9)">
                                        {{ getMoiveByRowAndColumn(row, column).score }}
                                    </span>

                                    <img class="movie-thumbnail" v-lazy="getMoiveByRowAndColumn(row, column).image_poster"
                                        style="max-width:100%;max-height:100%;vertical-align: middle; margin: 0 auto;"
                                        @click="clickMovie(getMoiveByRowAndColumn(row, column))">
                                    </div>
                            </div>
                            <div style="margin-top:10px">
                                <p align="left" style="font-size:16px; font-weight:700; font-family: PingFangdSC;">
                                    {{ getMoiveByRowAndColumn(row, column).title }}
                                </p>
                                <p align="left" style="font-size:14px; font-weight:500; font-family: PingFangdSC;">
                                    {{ getMoiveByRowAndColumn(row, column).types }}
                                </p>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </main>

            <footer class="ant-layout-footer">
                <Foot></Foot>
            </footer>

        </section>
    </div>
</template>

<script>

import Head from '@/components/common/Head'
import Foot from '@/components/common/Foot'
import CategoryFilterTags from '@/components/category/CategoryFilterTags'

import { fetchRinhomeDataService } from '@/services/rinhomeService.js'


export default {
    name: 'CategoryPage',
    data() {
        return {
            page: 1,
            // 单行显示数量
            maxColumnOfRow: 6,
            data: null,
        }

    },
    props: {
        mediaType: ""
    },
    components:{
        Head,
        Foot,
        CategoryFilterTags,
    },
    computed: {
        // 计算属性的 getter
        totalRow: function () {
            // * 计算当先数据可显示行数
            if (this.data.length) {
                var total = Math.ceil(this.data.length / this.maxColumnOfRow)
                return total
            }
            return 0
        }
    },
    methods: {
        /**
        * @func 电影点击响应
        *
        * @param {object} selectMovie: 选中电影object
        */
        clickMovie(selectedMovie) {
            console.log(selectedMovie.btbo_downlist)
            this.copyToClipboard(selectedMovie.btbo_downlist)
        },
        fetchNewData() {
            fetchRinhomeDataService(this.mediaType, response => {
                console.log(response.data)
                this.data = response.data
            }, error => {
            })
        },
        /**
        * @func calc: row 包含多少列
        *
        * @param {Int} row: 行数
        * @return {Int}: 当前行所显示列数
        */
        calcColumnByRow(row) {
            // 非最后一行返回最大显示数
            if (row < this.totalRow) {
                return this.maxColumnOfRow
            }
            var tmp = this.data.length - (this.maxColumnOfRow * (this.totalRow - 1))
            return tmp
        },
        /**
        * @func 获取数组内电影数据 by row, column
        *
        * @param {Int} row: 行数
        * @param {Int} column: 列数
        * @returns {Object} 电影数据
        */
        getMoiveByRowAndColumn(row, column) {
            var tmp = (row - 1) * this.maxColumnOfRow
            return this.data[tmp + column - 1]
        },
        /**
         * 拷贝文本到剪切板
         * @param {String} msg 拷贝内容
         */
        copyToClipboard(msg) {
            const input = document.createElement("input");  // 直接构建input
            input.value = msg                               // 设置内容
            document.body.appendChild(input);               // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy");                   // 执行复制
            document.body.removeChild(input);               // 删除临时实例
        }
    },
    mounted () {
        this.fetchNewData()
    },
}

</script>

<style>

.movie-thumbnail {
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.6s;
}

.movie-thumbnail:hover{
    transform: scale(1.05);
}

p:hover {
    color: #228efc
}

</style>
